import { service } from '@server/client';
import type {
    AuthEnsureAccountDtoInput,
    EnsureAccountMutation,
    EnsureAccountMutationVariables,
} from '@server/gql/graphql';
import { ensureAccountMutation } from '@server/queries/account';

export const ensureAccount = async ({
    payload,
}: {
    payload: AuthEnsureAccountDtoInput;
}) => {
    try {
        const response = await service<
            EnsureAccountMutation,
            EnsureAccountMutationVariables
        >({
            query: ensureAccountMutation,
            variables: {
                payload,
            },
        });
        if (!response) {
            return {
                ok: false,
                data: null,
                error: null,
            };
        }
        if (
            response.ensureAccount.__typename === 'AuthEnsureAccountResultDto'
        ) {
            return {
                ok: true,
                data: response.ensureAccount,
                error: null,
            };
        }
        if (response.ensureAccount.__typename === 'ProblemDetails') {
            return {
                ok: false,
                data: null,
                error: response.ensureAccount,
            };
        }
        return {
            ok: false,
            data: null,
            error: null,
        };
    } catch (e: any) {
        console.log(e);
        // console.group(
        //     ...e.response.errors.map((err: any) => console.debug(err)),
        //     console.debug('(in ensureAccount)'),
        // );
        return {
            ok: false,
            data: null,
            error: null,
        };
    }
};
