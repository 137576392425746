import { graphql } from '@server/gql';

export const deleteAccountMutation = graphql(/* GraphQL */ `
    mutation deleteAccount($token: String!) {
        deleteAccount(token: $token) {
            __typename
            isError
            data {
                ...genericResponse
            }
            error {
                ...problemDetails
                ...genericResponse
            }
        }
    }
`);
