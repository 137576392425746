import { graphql } from '@server/gql';

export const preauthAccountMutation = graphql(/* GraphQL */ `
    mutation preauthAccount($payload: AuthPreAuthDtoInput!, $token: String!) {
        preauthAccount(payload: $payload, token: $token) {
            __typename
            ... on AuthResponseDto {
                __typename
                reauthNecessary
            }
            ...problemDetails
            ...genericResponse
        }
    }
`);
