import { service } from '@server/client';
import type {
    UpdateAccountProfileMutation,
    UpdateAccountProfileMutationVariables,
    UserProfileUpdateDtoInput,
} from '@server/gql/graphql';
import { updateAccountProfileMutation } from '@server/queries/account';

export const updateAccountProfile = async ({
    payload,
    token,
}: {
    payload: UserProfileUpdateDtoInput;
    token: string;
}) => {
    try {
        const response = await service<
            UpdateAccountProfileMutation,
            UpdateAccountProfileMutationVariables
        >({
            query: updateAccountProfileMutation,
            variables: {
                payload,
                token,
            },
        });
        if (
            response.updateAccountProfile.__typename ===
            'UserProfileResponseDto'
        ) {
            return {
                ok: true,
                data: response.updateAccountProfile,
                error: null,
            };
        }
        if (
            response.updateAccountProfile.__typename ===
            'UserProfileUpdateErrorResultDto'
        ) {
            return {
                ok: true,
                data: response.updateAccountProfile,
                error: null,
            };
        }
        if (response.updateAccountProfile.__typename === 'ProblemDetails') {
            return {
                ok: false,
                data: null,
                error: response.updateAccountProfile,
            };
        }
        return {
            ok: false,
            data: null,
            error: null,
        };
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in updateAccountProfile)'),
        );
        return {
            ok: false,
            data: null,
            error: null,
        };
    }
};
