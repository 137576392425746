import { graphql } from '@server/gql';

export const registerAccountMutation = graphql(/* GraphQL */ `
    mutation registerAccount($payload: AuthRegisterAccountDtoInput!) {
        registerAccount(payload: $payload) {
            __typename
            ... on AuthRegisterResultDto {
                __typename
                firebaseUserId
                success
                validationErrors {
                    validationError
                    validationErrorDetails
                }
            }
            ...problemDetails
        }
    }
`);
