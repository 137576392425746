import { service } from '@server/client';
import type {
    UpdateAccountSettingsMutation,
    UpdateAccountSettingsMutationVariables,
    UserSettingsUpdateDtoInput,
} from '@server/gql/graphql';
import { updateAccountSettingsMutation } from '@server/queries/account';

export const updateAccountSettings = async ({
    payload,
    token,
}: {
    payload: UserSettingsUpdateDtoInput;
    token: string;
}) => {
    try {
        const response = await service<
            UpdateAccountSettingsMutation,
            UpdateAccountSettingsMutationVariables
        >({
            query: updateAccountSettingsMutation,
            variables: {
                payload,
                token,
            },
        });
        if (
            response.updateAccountSettings.__typename ===
            'UserSettingsResponseDto'
        ) {
            return {
                ok: true,
                data: response.updateAccountSettings,
                error: null,
            };
        }
        if (response.updateAccountSettings.__typename === 'ProblemDetails') {
            return {
                ok: false,
                data: null,
                error: response.updateAccountSettings,
            };
        }
        return {
            ok: false,
            data: null,
            error: null,
        };
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in updateAccountSettings)'),
        );
        return {
            ok: false,
            data: null,
            error: null,
        };
    }
};
