import { envConfig } from '@/config/env';

export const getAppConfig = (locale?: string) => {
    /* Denmark */
    if (locale === 'dk') return { market: 'dk', language: 'da' };
    if (locale === 'en-dk') return { market: 'dk', language: 'en' };
    /* Finland */
    if (locale === 'fi') return { market: 'fi', language: 'fi' };
    if (locale === 'en-fi') return { market: 'fi', language: 'en' };
    /* Norway */
    if (locale === 'no') return { market: 'no', language: 'nb' };
    if (locale === 'en-no') return { market: 'no', language: 'en' };
    /* Germany */
    if (locale === 'de') return { market: 'de', language: 'de' };
    if (locale === 'en-de') return { market: 'de', language: 'en' };
    /* Belgium */
    if (locale === 'be') return { market: 'be', language: 'be' };
    if (locale === 'en-be') return { market: 'be', language: 'en' };
    /* Netherlands */
    if (locale === 'nl') return { market: 'nl', language: 'nl' };
    if (locale === 'en-nl') return { market: 'nl', language: 'en' };
    /* Austria */
    if (locale === 'at') return { market: 'at', language: 'at' };
    if (locale === 'en-at') return { market: 'at', language: 'en' };
    /* France */
    if (locale === 'fr') return { market: 'fr', language: 'fr' };
    if (locale === 'en-fr') return { market: 'fr', language: 'en' };
    /* Spain */
    if (locale === 'es') return { market: 'es', language: 'es' };
    if (locale === 'en-es') return { market: 'es', language: 'en' };

    /* Let config decide, i.e. Sweden/Denmark/EU */
    return {
        market: envConfig.NEXT_PUBLIC_APP_MARKET ?? 'de',
        language: envConfig.NEXT_PUBLIC_APP_LANGUAGE ?? 'en',
    };
};
