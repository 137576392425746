import { service } from '@server/client';
import type {
    AuthPreAuthDtoInput,
    PreauthAccountMutation,
    PreauthAccountMutationVariables,
} from '@server/gql/graphql';
import { preauthAccountMutation } from '@server/queries/account';

export const preauthAccount = async ({
    payload,
    token,
}: {
    payload: AuthPreAuthDtoInput;
    token: string;
}) => {
    try {
        const response = await service<
            PreauthAccountMutation,
            PreauthAccountMutationVariables
        >({
            query: preauthAccountMutation,
            variables: {
                payload,
                token,
            },
        });
        if (response.preauthAccount.__typename === 'AuthResponseDto') {
            return {
                ok: true,
                data: response.preauthAccount,
                error: null,
            };
        }
        if (response.preauthAccount.__typename === 'ProblemDetails') {
            return {
                ok: false,
                data: null,
                error: response.preauthAccount,
            };
        }
        return {
            ok: false,
            data: null,
            error: null,
        };
    } catch (error: Error | unknown) {
        if (error instanceof Error) {
            console.error('preauthAccount error', error);
        }

        return {
            ok: false,
            data: null,
            error: null,
        };
    }
};
