'use client';

import { AUTH_COOKIE_NAME } from '@auth/helpers';
import type { AuthRegisterAccountDtoInput } from '@server/gql/graphql';
import { preauthAccount } from '@server/requests/account/preauthAccount';
import { registerAccount } from '@server/requests/account/registerAccount';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteCookie, setCookie } from 'cookies-next';
import type { User } from 'firebase/auth';
import {
    confirmPasswordReset,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    onIdTokenChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updatePassword,
    verifyPasswordResetCode,
} from 'firebase/auth';
import { useRouter } from 'next/navigation';
import type { ReactNode } from 'react';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import type { Authentication } from 'types/authentication';

import firebaseApp from '@/config/firebaseApp';
import signinWithEmail from '@/utils/auth/signinWithEmail';
import signinWithFacebook from '@/utils/auth/signinWithFacebook';
import signinWithGoogle from '@/utils/auth/signinWithGoogle';
import { getAppLocale, getServiceLocale } from '@/utils/const';
import { getAppConfig } from '@/utils/getAppConfig';
import { setVoyadoContactId } from '@/utils/voyadoTracking';

import { useSiteInfo } from './siteInfoContext';

class ValidationError {
    readonly errors: {
        validationError: string;
        validationErrorDetails: string;
    }[];
    readonly name: string;
    constructor(
        errors: {
            validationError: string;
            validationErrorDetails: string;
        }[],
    ) {
        this.name = 'ValidationError';
        this.errors = errors;
    }
}

const AuthContext = createContext<Authentication>({
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
    changePassword: () => Promise.resolve(),
    verifyCode: () => Promise.resolve(),
    verifyPassword: () => Promise.resolve(),
    loginWithEmail: () => Promise.resolve(),
    loginWithGoogle: () => Promise.resolve(),
    loginWithFacebook: () => Promise.resolve(),
    showLogin: () => null,
    hideLogin: () => null,
    showReset: () => null,
    hideReset: () => null,
    showSignup: () => null,
    hideSignup: () => null,
    showError: () => null,
    userId: null,
});

export type AuthState = {
    login: boolean;
    reset: boolean;
    error: null | string;
    loading: boolean;
    signup: boolean;
    redirect: boolean;
} & ({ user: User; token: string } | { user: null; token?: never });

export const PROVIDER_ERRORS = [
    'auth/account-exists-with-different-credential',
    'auth/cancelled-popup-request',
];
export const USER_REQUIRES_REAUTH = 'auth/requires-recent-login';

export function AuthProvider({
    children,
    locale,
}: {
    children: ReactNode;
    locale?: string;
}) {
    const { myPagesStartPage } = useSiteInfo();
    const serviceLocale = useMemo(() => {
        const config = getAppConfig(locale);
        return getServiceLocale(config.market, config.language);
    }, [locale]);
    const myPagesLoginUrl = useMemo(() => {
        const slug = myPagesStartPage?.slug ?? '';
        const config = getAppConfig(locale);
        const appLocale = getAppLocale(config.market, config.language);
        if (!appLocale) return slug;
        return `/${appLocale}${slug}`;
    }, [locale, myPagesStartPage]);
    const myPagesLogoutUrl = useMemo(() => {
        const config = getAppConfig(locale);
        return `/${getAppLocale(config.market, config.language)}`;
    }, [locale]);
    const queryClient = useQueryClient();
    const { data } = useQuery<AuthState>({
        queryKey: ['auth'],
        initialData: {
            user: null,
            login: false,
            reset: false,
            error: null,
            token: undefined,
            loading: false,
            redirect: true,
            signup: false,
        },
    });
    const { mutate } = useMutation({
        mutationKey: ['auth'],
        mutationFn: (value: Partial<AuthState>) => {
            queryClient.setQueryData(['auth'], (old: AuthState) => ({
                ...old,
                ...value,
            }));
            return Promise.resolve();
        },
    });
    const router = useRouter();
    const [auth] = useState(() => {
        const client = getAuth(firebaseApp);
        client.languageCode = getAppConfig(locale).language;
        return client;
    });

    const [facebookProvider] = useState(() => new FacebookAuthProvider());
    const [googleProvider] = useState(() => new GoogleAuthProvider());

    useEffect(() => {
        const observer = onIdTokenChanged(auth, async (authUser) => {
            if (!authUser) {
                deleteCookie(AUTH_COOKIE_NAME);
                mutate({ user: authUser });
            } else {
                const token = await authUser.getIdToken();
                mutate({ user: authUser, token });
                const verification = await preauthAccount({
                    payload: { brand: 'NG', locale: serviceLocale },
                    token: token,
                });
                if (verification.ok) {
                    setCookie(AUTH_COOKIE_NAME, token);
                    mutate({ user: authUser, token });
                } else {
                    await signOut(auth);
                    deleteCookie(AUTH_COOKIE_NAME);
                    mutate({ user: null });
                }
            }
        });
        return () => observer();
    }, [auth, mutate, serviceLocale]);

    useEffect(() => {
        if (data.token) {
            setVoyadoContactId(data.token);
        }
    }, [data.token]);

    const loginWithGoogle = useCallback(async () => {
        mutate({ loading: true });
        try {
            const response = await signinWithGoogle({
                auth,
                locale: serviceLocale,
                provider: googleProvider,
            });
            if (data.redirect) router.prefetch(myPagesLoginUrl);
            const token = await response.getIdToken();
            mutate({ user: response, token, login: false, loading: false });
            if (data.redirect) router.push(myPagesLoginUrl);
        } catch (e: any) {
            mutate({ error: e.code, loading: false });
        }
    }, [
        auth,
        router,
        serviceLocale,
        mutate,
        googleProvider,
        data.redirect,
        myPagesLoginUrl,
    ]);

    const loginWithFacebook = useCallback(async () => {
        mutate({ loading: true });
        try {
            const response = await signinWithFacebook({
                auth,
                locale: serviceLocale,
                provider: facebookProvider,
            });
            if (data.redirect) router.prefetch(myPagesLoginUrl);
            const token = await response.getIdToken();
            mutate({ user: response, token, login: false, loading: false });
            if (data.redirect) router.push(myPagesLoginUrl);
        } catch (e: any) {
            mutate({ error: e.code, loading: false });
        }
    }, [
        auth,
        router,
        serviceLocale,
        mutate,
        facebookProvider,
        data.redirect,
        myPagesLoginUrl,
    ]);

    const loginWithEmail = useCallback(
        async (email: string, password: string) => {
            mutate({ loading: true });
            try {
                const response = await signinWithEmail({
                    auth,
                    email,
                    locale: serviceLocale,
                    password,
                });
                if (data.redirect) router.prefetch(myPagesLoginUrl);
                const token = await response.getIdToken();
                mutate({ user: response, token, login: false, loading: false });
                if (data.redirect) router.push(myPagesLoginUrl);
            } catch (e: any) {
                mutate({ error: e.code, loading: false });
            }
        },
        [auth, router, serviceLocale, mutate, data.redirect, myPagesLoginUrl],
    );

    const register = useCallback(
        async (payload: AuthRegisterAccountDtoInput, password: string) => {
            mutate({ loading: true });
            router.prefetch(myPagesLoginUrl);
            try {
                if (!payload.email) throw new Error('Missing email');
                const register = await registerAccount({ payload });
                if (!register.ok) throw new Error('Registration failed');
                if (register.data?.validationErrors?.length) {
                    throw new ValidationError(
                        register.data?.validationErrors as any,
                    );
                }
                const response = await signInWithEmailAndPassword(
                    auth,
                    payload.email,
                    password,
                );
                const token = await response.user.getIdToken();
                mutate({
                    user: response.user,
                    token,
                    loading: false,
                });
                router.push(myPagesLoginUrl);
            } catch (e: any) {
                if (e.name === 'ValidationError') {
                    mutate({ user: null, loading: false });
                    throw e.errors;
                }
                if (e.code) {
                    mutate({ user: null, error: e.code, loading: false });
                }
                mutate({ user: null, error: e, loading: false });
            }
        },
        [auth, router, mutate, myPagesLoginUrl],
    );

    const changePassword = useCallback(
        async (password: string, oldPassword: string) => {
            if (!auth.currentUser?.email) return;
            mutate({ loading: true });
            try {
                await signInWithEmailAndPassword(
                    auth,
                    auth.currentUser.email,
                    oldPassword,
                );
                const response = await updatePassword(
                    auth.currentUser,
                    password,
                );
                mutate({ loading: false });
                return response;
            } catch (e: any) {
                mutate({ user: null, error: e.code, loading: false });
                throw e.code;
            }
        },
        [auth, mutate],
    );

    const resetPassword = useCallback(
        async (email: string) => {
            mutate({ loading: true });
            try {
                await sendPasswordResetEmail(auth, email);
            } catch (e: any) {
                mutate({ error: e.code, loading: false });
                throw e.code;
            }
        },
        [auth, mutate],
    );
    const verifyCode = useCallback(
        async (verificationCode: string) => {
            mutate({ loading: true });
            try {
                await verifyPasswordResetCode(auth, verificationCode);
                mutate({ loading: false });
            } catch (e: any) {
                mutate({ error: e.code, loading: false });
                throw e.code;
            }
        },
        [auth, mutate],
    );
    const verifyPassword = useCallback(
        async (verificationCode: string, password: string) => {
            mutate({ loading: true });
            try {
                await confirmPasswordReset(auth, verificationCode, password);
                mutate({ loading: false });
            } catch (e: any) {
                mutate({ error: e.code, loading: false });
                throw e.code;
            }
        },
        [auth, mutate],
    );

    const logout = useCallback(async () => {
        mutate({ loading: true });
        if (!auth.currentUser) return;
        try {
            await signOut(auth);
            deleteCookie(AUTH_COOKIE_NAME);
            mutate({ user: null, token: undefined, loading: false });
            router.push(myPagesLogoutUrl);
        } catch (e: any) {
            mutate({ error: e.code, loading: false });
            throw e.code;
        }
    }, [auth, router, myPagesLogoutUrl, mutate]);

    const showLogin = useCallback(
        (redirect = true) => {
            mutate({
                loading: false,
                error: null,
                login: true,
                redirect,
                signup: false,
            });
        },
        [mutate],
    );
    const hideLogin = useCallback(() => {
        mutate({ loading: false, error: null, login: false, reset: false });
    }, [mutate]);
    const showReset = useCallback(() => {
        mutate({ loading: false, error: null, login: true, reset: true });
    }, [mutate]);
    const hideReset = useCallback(() => {
        mutate({ loading: false, error: null, login: true, reset: false });
    }, [mutate]);
    const showSignup = useCallback(() => {
        mutate({ loading: false, error: null, signup: true });
    }, [mutate]);
    const hideSignup = useCallback(() => {
        mutate({ loading: false, error: null, signup: false });
    }, [mutate]);
    const showError = useCallback(
        (error: string) => {
            mutate({ loading: false, error });
        },
        [mutate],
    );

    const value: Authentication = useMemo(
        () => ({
            logout,
            register,
            resetPassword,
            changePassword,
            verifyCode,
            verifyPassword,
            loginWithEmail,
            loginWithGoogle,
            loginWithFacebook,
            showLogin,
            hideLogin,
            showReset,
            hideReset,
            showSignup,
            hideSignup,
            showError,
            userId: data?.user?.uid ?? null,
        }),
        [
            logout,
            register,
            resetPassword,
            changePassword,
            verifyCode,
            verifyPassword,
            loginWithEmail,
            loginWithGoogle,
            loginWithFacebook,
            showLogin,
            hideLogin,
            showReset,
            hideReset,
            showSignup,
            hideSignup,
            showError,
            data?.user?.uid,
        ],
    );

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within a AuthProvider');
    }
    return context;
};
