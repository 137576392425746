import { graphql } from '@server/gql';

export const getAccountSettingsQuery = graphql(/* GraphQL */ `
    query getAccountSettings($token: String!) {
        getAccountSettings(token: $token) {
            __typename
            ... on UserSettingsResponseDto {
                __typename
                acceptsEmail
                acceptsSms
            }
            ...problemDetails
            ...genericResponse
        }
    }
`);
