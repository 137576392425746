import { AUTH_COOKIE_NAME } from '@app/_auth/helpers';
import { preauthAccount } from '@server/requests/account/preauthAccount';
import { setCookie } from 'cookies-next';
import type { Auth, Persistence } from 'firebase/auth';
import {
    browserLocalPersistence,
    setPersistence,
    signInWithEmailAndPassword,
} from 'firebase/auth';

type EmailSigninProps = {
    type?: Persistence;
    auth: Auth;
    email: string;
    locale?: string;
    password: string;
};

export default async function signinWithEmail({
    type = browserLocalPersistence,
    auth,
    email,
    locale,
    password,
}: EmailSigninProps) {
    await setPersistence(auth, type);

    const response = await signInWithEmailAndPassword(auth, email, password);

    if (!response.user) throw new Error('Error: no user');

    const currentToken = await response.user.getIdTokenResult();

    const logIn = await preauthAccount({
        payload: {
            brand: 'NG',
            // brand: process.env.NEXT_PUBLIC_BRAND,
            locale,
        },
        token: currentToken.token,
    });

    if (!logIn.ok) throw new Error('Failed login');

    if (logIn.data?.reauthNecessary) {
        const freshToken = await response.user.getIdTokenResult(true);
        const reAuth = await preauthAccount({
            payload: {
                brand: 'NG',
                // brand: process.env.NEXT_PUBLIC_BRAND,
                locale,
            },
            token: freshToken.token,
        });
        if (!reAuth.ok) throw new Error('Failed login');
        const maxAge =
            new Date(freshToken.expirationTime).getTime() - Date.now();
        setCookie(AUTH_COOKIE_NAME, freshToken.token, {
            maxAge,
            httpOnly: false,
            secure: true,
        });
        return response.user;
    }
    const maxAge = new Date(currentToken.expirationTime).getTime() - Date.now();
    setCookie(AUTH_COOKIE_NAME, currentToken.token, {
        maxAge,
        httpOnly: false,
        secure: true,
    });
    return response.user;
}
