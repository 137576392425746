import { AUTH_COOKIE_NAME } from '@app/_auth/helpers';
import { ensureAccount } from '@server/requests/account/ensureAccount';
import { preauthAccount } from '@server/requests/account/preauthAccount';
import { setCookie } from 'cookies-next';
import type { Auth, GoogleAuthProvider, Persistence } from 'firebase/auth';
import {
    browserLocalPersistence,
    getAdditionalUserInfo,
    setPersistence,
    signInWithPopup,
} from 'firebase/auth';

import { getAppConfig } from '@/utils/getAppConfig';

type GoogleSigninProps = {
    type?: Persistence;
    auth: Auth;
    locale?: string;
    provider: GoogleAuthProvider;
};

export default async function signinWithGoogle({
    type = browserLocalPersistence,
    auth,
    locale,
    provider,
}: GoogleSigninProps) {
    const { market } = getAppConfig(locale);
    await setPersistence(auth, type);
    const response = await signInWithPopup(auth, provider);

    if (!response.user) throw new Error('Error');

    const info = getAdditionalUserInfo(response);

    const payload = {
        firebaseAccountId: response.user.uid,
        firstName: info?.profile?.given_name as string,
        lastName: info?.profile?.family_name as string,
        birthDate: '',
        brand: 'NG',
        // brand: process.env.NEXT_PUBLIC_BRAND,
        locale,
        countryCode: market,
    };
    const signUp = await ensureAccount({ payload });

    if (!signUp.ok) throw new Error('Failed signup');

    const currentToken = await response.user.getIdTokenResult();

    const logIn = await preauthAccount({
        payload: {
            brand: 'NG',
            // brand: process.env.NEXT_PUBLIC_BRAND,
            locale,
        },
        token: currentToken.token,
    });

    if (!logIn.ok) throw new Error('Failed login');

    if (logIn.data?.reauthNecessary) {
        const freshToken = await response.user.getIdTokenResult(true);
        const reAuth = await preauthAccount({
            payload: {
                brand: 'NG',
                // brand: process.env.NEXT_PUBLIC_BRAND,
                locale,
            },
            token: freshToken.token,
        });
        if (!reAuth.ok) throw new Error('Failed login');
        const maxAge =
            new Date(freshToken.expirationTime).getTime() - Date.now();
        setCookie(AUTH_COOKIE_NAME, freshToken.token, {
            maxAge,
            httpOnly: false,
            secure: true,
        });
        return response.user;
    }
    const maxAge = new Date(currentToken.expirationTime).getTime() - Date.now();
    setCookie(AUTH_COOKIE_NAME, currentToken.token, {
        maxAge,
        httpOnly: false,
        secure: true,
    });
    return response.user;
}
