import { graphql } from '@server/gql';

export const getAccountOrderQuery = graphql(/* GraphQL */ `
    query getAccountOrder($orderId: String!, $token: String!) {
        order: getAccountOrder(orderId: $orderId, token: $token) {
            __typename
            ... on OrderResponseDto {
                __typename
                billToAddress {
                    city
                    country
                    name
                    street
                    zipCode
                }
                shipToAddress {
                    city
                    country
                    name
                    street
                    zipCode
                }
                summary {
                    shippingAmount
                    totalDiscountAmount
                    totalOrderAmountIncVat
                    totalVatAmount
                }
                items {
                    partNo
                    brandName
                    imageKey
                    name
                    quantity
                    rowAmountIncVat
                    stockStatus
                    unitPriceIncVat
                }
                orderStatus: status
                billToEmailAddress
                deliveryDate
                deliveryMethod
                hasReceipt
                orderDate
                orderId
                paymentMethod
                sellToEmailAddress
                sellToPhoneNumber
                shipToEmailAddress
                trackingLink
            }
            ...problemDetails
            ...genericResponse
        }
    }
`);
