import { service } from '@server/client';
import type {
    GetAccountOrderQuery,
    GetAccountOrderQueryVariables,
} from '@server/gql/graphql';
import { getAccountOrderQuery } from '@server/queries/account';

export const getAccountOrder = async ({
    orderId,
    token,
}: {
    orderId: string;
    token: string;
}) => {
    try {
        const response = await service<
            GetAccountOrderQuery,
            GetAccountOrderQueryVariables
        >({
            query: getAccountOrderQuery,
            variables: {
                orderId,
                token,
            },
        });
        if (response.order.__typename === 'OrderResponseDto') {
            return {
                ok: true,
                data: response.order,
                error: null,
            };
        }
        if (response.order.__typename === 'ProblemDetails') {
            return {
                ok: false,
                data: null,
                error: response.order,
            };
        }
        return {
            ok: false,
            data: null,
            error: null,
        };
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getAccountOrder)'),
        );
        return {
            ok: false,
            data: null,
            error: null,
        };
    }
};
