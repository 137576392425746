import { service } from '@server/client';
import type {
    GetAccountProfileQuery,
    GetAccountProfileQueryVariables,
} from '@server/gql/graphql';
import { getAccountProfileQuery } from '@server/queries/account';

export const getAccountProfile = async ({ token }: { token: string }) => {
    try {
        const response = await service<
            GetAccountProfileQuery,
            GetAccountProfileQueryVariables
        >({
            query: getAccountProfileQuery,
            variables: {
                token,
            },
        });
        if (
            response.getAccountProfile.__typename === 'UserProfileResponseDto'
        ) {
            return {
                ok: true,
                data: response.getAccountProfile,
                error: null,
            };
        }
        if (response.getAccountProfile.__typename === 'ProblemDetails') {
            return {
                ok: false,
                data: null,
                error: response.getAccountProfile,
            };
        }
        return {
            ok: false,
            data: null,
            error: null,
        };
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getAccountProfile)'),
        );
        return {
            ok: false,
            data: null,
            error: null,
        };
    }
};
