import { graphql } from '@server/gql';

export const ensureAccountMutation = graphql(/* GraphQL */ `
    mutation ensureAccount($payload: AuthEnsureAccountDtoInput!) {
        ensureAccount(payload: $payload) {
            __typename
            ... on AuthEnsureAccountResultDto {
                __typename
                success
                validationErrors {
                    validationError
                    validationErrorDetails
                }
            }
            ...problemDetails
        }
    }
`);
