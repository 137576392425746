import { graphql } from '@server/gql';

export const updateAccountProfileMutation = graphql(/* GraphQL */ `
    mutation updateAccountProfile(
        $payload: UserProfileUpdateDtoInput!
        $token: String!
    ) {
        updateAccountProfile(payload: $payload, token: $token) {
            __typename
            ... on UserProfileResponseDto {
                __typename
                city
                email
                firstName
                lastName
                phoneNumber
                socialSecurityNumber
                street
                zipCode
            }
            ... on UserProfileUpdateErrorResultDto {
                __typename
                validationErrors {
                    validationError
                    validationErrorDetails
                }
            }
            ...problemDetails
            ...genericResponse
        }
    }
`);
