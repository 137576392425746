import { service } from '@server/client';
import type {
    GetAccountSettingsQuery,
    GetAccountSettingsQueryVariables,
} from '@server/gql/graphql';
import { getAccountSettingsQuery } from '@server/queries/account';

export const getAccountSettings = async ({ token }: { token: string }) => {
    try {
        const response = await service<
            GetAccountSettingsQuery,
            GetAccountSettingsQueryVariables
        >({
            query: getAccountSettingsQuery,
            variables: {
                token,
            },
        });
        if (
            response.getAccountSettings.__typename === 'UserSettingsResponseDto'
        ) {
            return {
                ok: true,
                data: response.getAccountSettings,
                error: null,
            };
        }
        if (response.getAccountSettings.__typename === 'ProblemDetails') {
            return {
                ok: false,
                data: null,
                error: response.getAccountSettings,
            };
        }
        return {
            ok: false,
            data: null,
            error: null,
        };
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getAccountSettings)'),
        );
        return {
            ok: false,
            data: null,
            error: null,
        };
    }
};
