import { graphql } from '@server/gql';

export const updateAccountSettingsMutation = graphql(/* GraphQL */ `
    mutation updateAccountSettings(
        $payload: UserSettingsUpdateDtoInput!
        $token: String!
    ) {
        updateAccountSettings(payload: $payload, token: $token) {
            __typename
            ... on UserSettingsResponseDto {
                __typename
                acceptsEmail
                acceptsSms
            }
            ...genericResponse
            ...problemDetails
        }
    }
`);
