/* eslint-disable no-await-in-loop */

import { preauthAccount } from '@server/requests/account';
import type { IdTokenResult } from 'firebase/auth';
import type { Dictionary } from 'types/common';

import { SITE_BRAND } from '@/utils/const';

export const AUTH_COOKIE_NAME = 'auth-session';

type TAuthLogin = { reauth: boolean; error: string | null };
/**
 * Sends sets firebase token as cookie
 *
 * @param tokenResult The firebase token object
 * @returns object
 */
export const setAuthCookie = async (
    tokenResult: IdTokenResult,
): Promise<TAuthLogin> => {
    const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(tokenResult),
    });
    const data = await response.json();
    return data;
};

/**
 * Checks response and refreshes token if needed
 *
 * @param getToken Firebase user function to get id token result
 * @param locale
 * @returns object
 */
export const authLogin = async (
    getToken: (forceRefresh?: boolean | undefined) => Promise<IdTokenResult>,
    locale: string | undefined,
): Promise<any> => {
    // ): Promise<AuthPreAuthResult | undefined> => {
    let tokenResult = await getToken();

    let login = await preauthAccount({
        payload: {
            brand: SITE_BRAND,
            locale,
        },
        token: tokenResult.token,
        // client,
    });

    if (!login.ok) {
        // eslint-disable-next-line no-console
        console.debug(login.error?.detail ?? 'Login error');
        return undefined;
    }

    if (login.data?.reauthNecessary) {
        let needsReauth: boolean = login.data?.reauthNecessary;
        let tries = 1;

        while (needsReauth) {
            tokenResult = await getToken(true);

            login = await preauthAccount({
                payload: {
                    brand: SITE_BRAND,
                    locale,
                },
                token: tokenResult.token,
                // client,
            });

            if (!login.ok) {
                // eslint-disable-next-line no-console
                console.debug(login.error?.detail ?? 'Login error');
                return undefined;
            }
            needsReauth = login.data?.reauthNecessary || false;

            tries += 1;

            // Insurance to make sure we don't end in an infinite loop
            if (tries > 5) needsReauth = false;
        }
    }

    // Successful request and no re-auth necessary, set auth cookie
    if (login.ok && !login.data?.reauthNecessary) {
        await setAuthCookie(tokenResult);
    }

    return login;
};

/**
 * Deletes user token cookie
 *
 * @returns boolean
 */
export const authLogout = async (): Promise<boolean> => {
    await fetch('/api/auth/logout', {
        method: 'POST',
    });
    return true;
};

/**
 * Error handler for translating error codes to human-readable messages through dictionary.
 *
 * @param errorCode error code
 * @param dictionary a dictionary of error messages
 * @returns string
 */
export default function ErrorHandler(
    errorCode: string,
    dictionary: Dictionary,
) {
    switch (errorCode) {
        case 'auth/email-already-in-use':
            return { errorCode, msg: dictionary.emailAlreadyInUse };
        case 'auth/invalid-action-code':
            return {
                errorCode,
                msg: dictionary.errorPasswordResetTokenInvalid,
            };
        default:
            return { errorCode, msg: dictionary.errorOccured };
    }
}
