import { service } from '@server/client';
import type {
    DeleteAccountMutation,
    DeleteAccountMutationVariables,
} from '@server/gql/graphql';
import { deleteAccountMutation } from '@server/queries/account';

export const deleteAccount = async ({ token }: { token: string }) => {
    try {
        const response = await service<
            DeleteAccountMutation,
            DeleteAccountMutationVariables
        >({
            query: deleteAccountMutation,
            variables: {
                token,
            },
        });
        return response.deleteAccount;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in deleteAccount)'),
        );
        return e.response.data as DeleteAccountMutation;
    }
};
