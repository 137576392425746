import { graphql } from '@server/gql';

export const getAccountOrdersQuery = graphql(/* GraphQL */ `
    query getAccountOrders($token: String!) {
        getAccountOrders(token: $token) {
            __typename
            ... on OrderResponseItemsDto {
                __typename
                items {
                    orderId
                    orderDate
                    deliveryDate
                    status
                    deliveryMethod
                    paymentMethod
                    summary {
                        totalOrderAmountIncVat
                        shippingAmount
                        totalVatAmount
                        totalDiscountAmount
                    }
                    billToAddress {
                        name
                        street
                        zipCode
                        city
                        country
                    }
                    shipToAddress {
                        name
                        street
                        zipCode
                        city
                        country
                    }
                    items {
                        name
                        brandName
                        stockStatus
                        quantity
                        unitPriceIncVat
                        rowAmountIncVat
                        imageKey
                    }
                    trackingLink
                    hasReceipt
                    sellToEmailAddress
                    billToEmailAddress
                    shipToEmailAddress
                }
            }
            ...problemDetails
            ...genericResponse
        }
    }
`);
