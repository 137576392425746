import type { SlugsQuery } from '@server/gql/graphql';

export const COOKIE_MAX_AGE = 60 * 60 * 24 * 365;

export const SITE_BRAND = 'NG';

export const SEARCH_ENGINE_USER = 'ng-search';

export const TITLE_SITE_NAME = 'NO GA';
export const TITLE_DELIMITER = '|';
export const TITLE_PAGE_NUMBER_PREFIX = 'Page';
export const TITLE_PAGE_NUMBER_DELIMITER = '-';

export const CATEGORY_PAGE_DICTIONARY_ID = '26pPduK6BYLwGYoCshTMlt';

export const RECOMMENDED_PRICE_TEXT_BLOCK_NAME = 'Recommended price';

export const EU_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE = 100;
export const DK_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE = 1000;
export const FI_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE = 100;
export const NO_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE = 1000;
export const SE_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE = 1000;

export const EU_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE = 400;
export const DK_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE = 3000;
export const FI_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE = 400;
export const NO_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE = 3000;
export const SE_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE = 3000;

export const EU_MIN_SHIPPING_COST = 10;
export const DK_MIN_SHIPPING_COST = 69;
export const FI_MIN_SHIPPING_COST = 10;
export const NO_MIN_SHIPPING_COST = 99;
export const SE_MIN_SHIPPING_COST = 69;

export const EU_MAX_SHIPPING_COST = 20;
export const DK_MAX_SHIPPING_COST = 195;
export const FI_MAX_SHIPPING_COST = 20;
export const NO_MAX_SHIPPING_COST = 195;
export const SE_MAX_SHIPPING_COST = 195;

/**
 * Breadcrumbs.Code will only search for products for which the category is the primary/breadcrumbs category.
 * Category will search for products for which the category is the primary/breadcrumbs category or one of the additional categories.
 */
type CategoryAttribute = 'Category' | 'Breadcrumbs.Code';
type StatusAttribute = 'statuses';

export const RANGE_FILTER_ATTRIBUTES = [
    'Price',
    'depth_cm',
    'height_cm',
    'length_cm',
    'width_cm',
    'diameter_cm',
] as const;

export const DEFAULT_CATEGORY_ATTRIBUTE: CategoryAttribute = 'Category';
export const DEFAULT_STATUSES_ATTRIBUTE: StatusAttribute = 'statuses';

export type MarketAwareSlug = {
    slug: string;
    cleanSlug: string;
    parts: string[];
    contentfulLocale: string;
    locale?: string;
    market: string;
    language: string;
    error: boolean;
    pageNumber: number;
    serviceLocale: string;
};

export const ANONYMOUS_WISHLIST_TOKEN_NAME = 'Anonymous-Wishlist-Token';

export const ANONYMOUS_WISHLIST_ID_NAME = 'Anonymous-Wishlist-Id';

export const getServiceLocale = (market: string, language: string) => {
    /* Sweden */
    if (market === 'se' && language === 'sv') return 'sv-se';
    if (market === 'se' && language === 'en') return 'en-se';
    /* Norway */
    if (market === 'no' && language === 'nb') return 'nb-no';
    if (market === 'no' && language === 'en') return 'en-no';
    /* Denmark */
    if (market === 'dk' && language === 'da') return 'da-dk';
    if (market === 'dk' && language === 'en') return 'en-dk';
    /* Finland */
    if (market === 'fi' && language === 'fi') return 'fi-fi';
    if (market === 'fi' && language === 'en') return 'en-fi';
    /* Germany */
    if (market === 'de' && language === 'de') return 'de-de';
    if (market === 'de' && language === 'en') return 'en-de';
    /* Belgium */
    if (market === 'be' && language === 'be') return 'be-be';
    if (market === 'be' && language === 'en') return 'en-be';
    /* Netherlands */
    if (market === 'nl' && language === 'nl') return 'nl-nl';
    if (market === 'nl' && language === 'en') return 'en-nl';
    /* Austria */
    if (market === 'at' && language === 'at') return 'at-at';
    if (market === 'at' && language === 'en') return 'en-at';
    /* France */
    if (market === 'fr' && language === 'fr') return 'fr-fr';
    if (market === 'fr' && language === 'en') return 'en-fr';
    /* Spain */
    if (market === 'es' && language === 'es') return 'es-es';
    if (market === 'es' && language === 'en') return 'en-es';
    /* EU/Default */
    return 'en-de';
};

export const getAppLocale = (market: string, language: string) => {
    /* Sweden */
    if (market === 'se' && language === 'sv') return '';
    if (market === 'se' && language === 'en') return 'en-se';
    /* Norway */
    if (market === 'no' && language === 'nb') return '';
    if (market === 'no' && language === 'en') return 'en-no';
    /* Denmark */
    if (market === 'dk' && language === 'da') return 'dk';
    if (market === 'dk' && language === 'en') return 'en-dk';
    /* Finland */
    if (market === 'fi' && language === 'fi') return 'fi';
    if (market === 'fi' && language === 'en') return 'en-fi';
    /* Germany */
    if (market === 'de' && language === 'de') return 'de';
    if (market === 'de' && language === 'en') return 'en-de';
    /* Belgium */
    if (market === 'be' && language === 'be') return 'be';
    if (market === 'be' && language === 'en') return 'en-be';
    /* Netherlands */
    if (market === 'nl' && language === 'nl') return 'nl';
    if (market === 'nl' && language === 'en') return 'en-nl';
    /* Austria */
    if (market === 'at' && language === 'at') return 'at';
    if (market === 'at' && language === 'en') return 'en-at';
    /* France */
    if (market === 'fr' && language === 'fr') return 'fr';
    if (market === 'fr' && language === 'en') return 'en-fr';
    /* Spain */
    if (market === 'es' && language === 'es') return 'es';
    if (market === 'es' && language === 'en') return 'en-es';

    /* EU/Default */
    if (market === 'eu') return 'eu';

    return 'en-de';
};

export const getIsoLocale = ({
    market,
    language,
}: {
    market: string;
    language: string;
}) => `${language.toLowerCase()}-${market.toUpperCase()}`;

export const getNewCustomerUrl = (locale?: string) => {
    switch (locale) {
        case 'sv-se':
            return '/ny-kund';
        case 'dk':
            return '/dk/nykunde';
        case 'fi':
            return '/fi/uusasiakas';
        case 'nb-no':
            return '/nykunde-2';
        default:
            return `/${locale}/new-customer`;
    }
};

export const isValidSlug = (allSlugs: SlugsQuery, slug: string) => {
    const slugs = [
        ...(allSlugs.pages?.items ?? []),
        ...(allSlugs.articles?.items ?? []),
        ...(allSlugs.campaigns?.items ?? []),
        ...(allSlugs.landingPages?.items ?? []),
    ];
    return slugs
        .map((item) => item?.slug)
        .filter(Boolean)
        .includes(slug);
};
