import { graphql } from '@server/gql';

export const getAccountProfileQuery = graphql(/* GraphQL */ `
    query getAccountProfile($token: String!) {
        getAccountProfile(token: $token) {
            __typename
            ... on UserProfileResponseDto {
                __typename
                firstName
                lastName
                street
                zipCode
                city
                phoneNumber
                email
                socialSecurityNumber
                isCompany
                companyOrganizationalNumber
                companyName
            }
            ...problemDetails
            ...genericResponse
        }
    }
`);
