import { service } from '@server/client';
import type {
    AuthRegisterAccountDtoInput,
    RegisterAccountMutation,
    RegisterAccountMutationVariables,
} from '@server/gql/graphql';
import { registerAccountMutation } from '@server/queries/account';

export const registerAccount = async ({
    payload,
}: {
    payload: AuthRegisterAccountDtoInput;
}) => {
    try {
        const response = await service<
            RegisterAccountMutation,
            RegisterAccountMutationVariables
        >({
            query: registerAccountMutation,
            variables: {
                payload,
            },
        });
        if (response.registerAccount.__typename === 'AuthRegisterResultDto') {
            return {
                ok: true,
                data: response.registerAccount,
                error: null,
            };
        }
        if (response.registerAccount.__typename === 'ProblemDetails') {
            return {
                ok: false,
                data: null,
                error: response.registerAccount,
            };
        }
        return {
            ok: false,
            data: null,
            error: null,
        };
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in registerAccount)'),
        );
        return {
            ok: false,
            data: null,
            error: null,
        };
    }
};
